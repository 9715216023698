body {
  margin: 0;
  font-family: "Atkinson Hyperlegible", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Atkinson Hyperlegible", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

.background{
  background-image: linear-gradient(to right bottom, #fffdd0, #6599c2);
}
